// load all images from theme_url/img
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./img', true));

import $ from "expose-loader?exposes=$,jQuery!jquery";

// import bootstrap js
import './js/bootstrap.js';

// leaflet
import './js/leaflet/leaflet';

// custom scss
import './scss/app.scss';

// tenor js
import Tenor from 'tenor/init';
new Tenor({
    showHideFieldset: (valid, fieldset) => {
        if (valid) {
            // show fieldset
            $(fieldset).slideDown();
        }
        else {
            // hide fieldset
            $(fieldset).slideUp();
        }
    }
});

/** ganze Box verlinken Funktion **/
function linkWholeBox(cssClass) {
    var container = $(cssClass);

    if(container.find('a').length > 0) {
        container.css('cursor', 'pointer');

        container.click(function(){
            location.href = $(this).find('a').attr('href');
        });

        container.mouseover(function(){
            $(this).attr('title', $(this).find('a').attr('title'));
        });
    }
}


/** Alle Boxen verlinken **/
$(function() {
    linkWholeBox('.JobBox .Job');
    linkWholeBox('.jobgroup--box .Article');
    linkWholeBox('.article-icon');
});

$('.upload-button').click(function(e) {
    e.preventDefault();
    let id = $(this).attr('data-id');
    let button = $(id).find('input');
    button.trigger('click');
});

$(function() {
    $("input[type='file']").on( 'change', function(e)
    {
        console.log('YES');
        let id = $(this).attr('data-id');
        console.log(id);
        var fileName = e.target.value;
        fileName = fileName.split("\\").pop();
        $(id+' .upload-button').text(fileName);
    });
});